import "./style.scss";

import {Stack} from "../Stack/Stack";
import {MainTitle} from "../MainTitle";


import {EngineCard} from "../EngineCard/EngineCard";
import React, {useEffect, useRef, useState} from "react";
import {IconClose} from "../../assets/icons/IconClose";
import Slider from "react-slick";
import EmptyModal from "../EmptyModal/EmptyModal";
import {SwapMobile} from "../SwapMobile/SwapMobile";
import {IDevContent, IDevItem, ITableData} from "../../models/personal-settings.model";

interface Props {
  data: IDevContent | null
}

export const PersonalSettings = ({data}: Props) => {
  const [openModal, setOpenModal] = useState(false)
  const [devData, setDevData] = useState<ITableData<IDevItem> | null>(null)
  const [selectedItem, setSelectedItem] = useState<IDevItem | null>(null)
  const closeModal = () => {
    document.body.classList.remove('G-hidden')
    setOpenModal(false)
    setSelectedItem(null)
  }

  const openModalInfo = () => {
    document.body.classList.add('G-hidden')
    setOpenModal(true)
  }

  useEffect(() => {
    window.addEventListener('keydown', closeModalByEsc)
    return () => {
      window.removeEventListener('keydown', closeModalByEsc)
    }
    // eslint-disable-next-line
  }, [])

  function closeModalByEsc(e: KeyboardEvent) {
    if (e.key === 'Escape') {
      closeModal()
    }
  }

  useEffect(() => {
    const devDataJson = JSON.parse(data?.devsElement?.value ?? '{}')
    setDevData(devDataJson)
  }, [data])
  return (
    <>
      <section className={'personal-settings-section'} id={'projects'}>
        <Stack className={'G-container'}>
          <MainTitle tittle={data?.title.value ?? ''}/>
          <div className={'personal-settings-list G-flex-wrap'}>
            {devData ? devData?.table?.body.map((item, index) => {
              return <EngineCard key={index} className={'engine-card-1'} item={item} onClick={() => {
                setSelectedItem(item)
                openModalInfo()
              }}/>
            }) : null}
          </div>
        </Stack>

      </section>
      <SwapMobile disableClick={true} onSwipe={(e: any, option: string) => {
        if (option === 'right') {
          closeModal()
        }
      }}>
        <div className={`personal-settings-modal ${openModal ? 'open-modal' : ''}`}
        >
          <div className={'modal-bg'} onClick={closeModal}/>
          <div className={'personal-settings-modal-content'}>
            <div className={'personal-settings-modal-header G-justify-end'}>
                    <span className={'G-pointer'} onClick={closeModal}>
                        <IconClose/>
                    </span>
            </div>
            <div className='personal-settings-modal-body'>
              {selectedItem && <InformationCard data={selectedItem}/>}
            </div>
          </div>
        </div>
      </SwapMobile>
    </>
  );
};


const InformationCard = ({data}: { data: IDevItem }) => {

  const [isOpen, setIsOpen] = useState(false)
  const sliderRef = useRef<Slider>(null)
  const [sliderIndex, setIndex] = useState(0)
  useEffect(() => {
    if (isOpen && sliderRef.current) {
      setTimeout(() => {
        sliderRef.current && sliderRef.current.slickGoTo(sliderIndex)
      }, 0)
    }
  }, [isOpen, sliderIndex])

  const settings = {
    slidesToShow: 5,
    arrows: true,
    dots: false,
    infinite: true,
    responsive: [
      {
        breakpoint: 2800,
        settings: {
          slidesToShow: 4,
          dots: true,
        }
      },
      {
        breakpoint: 2200,
        settings: {
          slidesToShow: 3,
          dots: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          dots: true,
        }
      }, {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          dots: true,
        }
      },
    ]
  }
  return <>
    <Stack className={'modal-information'}>
      <h3>{data.title}</h3>

      {data.gallery ? <div className='modal-images ' onTouchMove={(e) => {
        e.stopPropagation()
      }}>
        {data.gallery.length > 1 ? <Slider {...settings} >
          {data.gallery.map((item, index) => {
            return <div className={'modal-slide-image'} key={index}>
              <ImageCard image={item.fileUrl} onClick={() => {
                setIsOpen(true)
                setIndex(index)
              }}/>
            </div>
          })}
        </Slider> : <div className={'modal-slide-image'}>
          <ImageCard image={data.gallery[0].fileUrl} onClick={() => {
            setIsOpen(true)
            setIndex(0)
          }}/>
        </div>}
      </div> : null}

      <p dangerouslySetInnerHTML={{__html: data.desc}}/>

    </Stack>
    <EmptyModal isOpenModal={isOpen} close={() => {
      setIsOpen(false)
    }}>
      {data.gallery ? <div className='popup-slider-wrapper' onTouchMove={(e) => {
        e.stopPropagation()
      }}>
        {data.gallery.length > 1 ? <Slider slidesToShow={1} arrows infinite
                                           ref={sliderRef}
        >
          {data.gallery.map((item, index) => {
            return <div className={'modal-slide-image'} key={index}>
              <ImageCard isSub image={item.fileUrl}/>
            </div>
          })}
        </Slider> : <div className={'modal-slide-image'}>
          <ImageCard isSub image={data.gallery[0].fileUrl}/>
        </div>}
      </div> : null}
    </EmptyModal>
  </>
}

const ImageCard = ({onClick, image, isSub}: {
                     onClick?(): void,
                     image: string,
                     isSub?: boolean
                   }
) => {
  return isSub ? <div className={'slider-popup G-center'}>
      <img src={image} alt="personal-settings"/>
    </div> :
    <div onClick={onClick} className={'modal-image-item'} style={{backgroundImage: `url('${image}')`}}/>
}

