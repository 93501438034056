import {Stack} from "../Stack/Stack";
import './style.scss'
import {IconLogo} from "../../assets/icons/IconLogo";
import {IconArrowSliderLeft} from "../../assets/icons/IconArrowSliderLeft";
import React, {useEffect} from "react";
import {IFooterContent} from "../../models/personal-settings.model";


interface Props {
  data: IFooterContent | null
}

export const Footer = ({data}: Props) => {
  const [addresses, setAddresses] = React.useState<{ title: string, value: string }[]>([])
  const [links, setLinks] = React.useState<{ title: string, path: string }[]>([])
  const [contacts, setContacts] = React.useState<string[]>([])

  const handleClick = (elementId: number) => {
    const itemText: string = generateLinks(elementId)
    const element = document.getElementById(itemText)
    if (element) {
      let headerOffset = 100;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  const handleScrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  useEffect(() => {
    const addressJson = JSON.parse(data?.addresses?.value ?? '{}')
    const linksJson: { title: string, path: string }[] = JSON.parse(data?.links?.value ?? '{}')
    const contactsJson = JSON.parse(data?.contacts?.value ?? '{}')

    setContacts(contactsJson)
    setAddresses(addressJson)
    setLinks(linksJson)
  }, [data])

  const generateLinks = (index: number) => {
    switch (index) {
      case 0:
        return 'projects'
      case 1:
        return 'advantages'
      case 2:
        return 'about-us'
      case 3:
        return 'our-team'
      default:
        return ''
    }
  }

  return <footer id={'contacts'}>
    <Stack className={'footer-tittle'} width={"fill"}>
      <Stack className={'G-container'} width={"fill"} justify={"space-between"} align={"center"} direction={"row"}>
        <h3> {data?.title?.value ?? ''} </h3>
        <Stack className={'go-top-content'} direction={"row"} align={"center"} gap={30} onClick={handleScrollTop}>
          <span><IconArrowSliderLeft size={20}/></span>
        </Stack>
      </Stack>
    </Stack>
    <Stack className={'footer-information'}>
      <Stack className={'G-container'} width={"fill"}>
        <Stack className={'footer-information-content'} direction={"row"} gap={20} justify={"space-between"}
               width={"fill"} align={"end"}>
          <Stack className={'footer-menu'}>
            <ul>
              {links.length ? links.map((item, index) => {
                return <li key={index} onClick={() => {
                  handleClick(index)
                }}>{item.title}</li>
              }) : null}
            </ul>
          </Stack>
          <Stack className={'footer-address'}>
            {addresses.length ? addresses.map((item, index) => {
              return <div key={index} className='footer-address-item'>
                <span>{item.title}</span>
                <p>{item.value}</p>
              </div>
            }) : null}

          </Stack>
          <Stack className={'footer-contact'} justify={"end"} align={"end"}>
            <Stack className={'go-top-content'} direction={"row"} align={"center"} gap={30} onClick={handleScrollTop}>
              <p>Наверх</p>
              <span><IconArrowSliderLeft size={20}/></span>
            </Stack>
            {contacts.length ? contacts.map((item, index) => {
              return <a key={index} href={`${item.indexOf('@') >= 0 ? 'mailto' : 'tel'}:${item}`}>{item}</a>
            }) : null}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
    <Stack className={'footer-end'} width={"fill"}>
      <Stack className={'G-container'} width={"fill"}>
        <div className={'footer-end-content'}>
          <Stack className={'footer-left'} direction={"row"} align={"center"} gap={55}>
            <div onClick={handleScrollTop}>
              <IconLogo size={42}/>
            </div>
            <h3>2024, Все права защищены</h3>
          </Stack>
          <Stack className={'footer-right'} direction={"row"} align={"center"} gap={8}>
            <h3>сайт разработан</h3>
            <a href={'https://formularf.ru/'} target="_blank" rel="noreferrer">ƒ∅rmuλa</a>
          </Stack>
        </div>
      </Stack>
    </Stack>
  </footer>
}
