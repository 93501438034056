import "./style.scss";
import {MainTitle} from "../MainTitle";
import aboutUsImage from '../../assets/images/about-us-bg.png'
import {useEffect, useRef, useState} from "react";
import useOnScreen from "../../hook/useOnScreen";
import {ITableData, IUniqueContent, IUniqueItem} from "../../models/personal-settings.model";


interface Props {
  data: IUniqueContent | null
}

export const AboutUs = ({data}: Props) => {
  const aboutUsRef = useRef<HTMLDivElement>(null)
  const isVisibleAboutUs = useOnScreen(aboutUsRef)
  const [uniqueData, setUniqueData] = useState<IUniqueItem[]>([])

  useEffect(() => {
    const uniqueDataJson: ITableData<IUniqueItem> = JSON.parse(data?.listElement?.value ?? '{}')
    setUniqueData(uniqueDataJson?.table?.body ?? [])
  }, [data])

  return (
    <section className={'about-section'} id={'advantages'}>
      <div className=" G-container">
        <MainTitle tittle={data?.title?.value ?? ''} className={'about-us-tittle'}/>
        <div className={`info-content ${isVisibleAboutUs ? 'show-info' : ''}`} ref={aboutUsRef}>
          {uniqueData.length ? uniqueData.map((item, index) => {
            return <div key={index} className="info-Box">
              <img src={item.icon.fileUrl} alt="icon"/>
              <h3>{item.title ?? ''}</h3>
              <p>{item.desc ?? ''}</p>
            </div>
          }) : null}
        </div>
        <div className={'about-us-bg'} style={{backgroundImage: `url('${aboutUsImage}')`}}/>
      </div>
    </section>
  );
};
