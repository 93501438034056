import {Stack} from "../Stack/Stack";
import './style.scss'

import Slider from "react-slick";
import {IconArrowSliderRight} from "../../assets/icons/IconArrowSliderRight";
import {IconArrowSliderLeft} from "../../assets/icons/IconArrowSliderLeft";
import {useRef, useState} from "react";
import useOnScreen from "../../hook/useOnScreen";
import {ITemProps} from "../../models/personal-settings.model";

interface Props {
  data: ITemProps | null
}

export const OurTeam = ({data}: Props) => {
  const sectionRef = useRef<HTMLDivElement>(null)
  const isVisibleSection = useOnScreen(sectionRef)
  const slider = useRef<Slider>(null);
  const [activeSlider, setActiveSlider] = useState(1)


  let settings = {
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    afterChange: (data: number) => {
      setActiveSlider(data + 1)
    },

    responsive: [
      {
        breakpoint: 1700,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        }
      },
    ]
  };


  return <section className={`our-team-section ${isVisibleSection ? 'show-section' : ''}`} id={'our-team'}
                  ref={sectionRef}>
    <Stack className={'G-container'} width={"fill"}>
      <Stack className={'our-team-content'} direction={"row"} gap={30} width={"fill"} justify={"space-between"}>
        <div className={'our-team-info'}>
          <Stack className={'our-team-title'}>
            <h3>{data?.title?.value ?? ""}</h3>
            <p>{data?.desc?.value ?? ""}</p>
          </Stack>
          {window.innerWidth > 991 ? <Stack className={'slider-options'}>
            <Stack className={'slider-counter'} direction={"row"} align={"end"}>
              <h3>0{activeSlider}</h3>
              <p>/ 0{data?.gallery.items.length}</p>
            </Stack>
            <Stack direction={"row"} gap={15}>
              <div
                onClick={() => {
                  slider?.current?.slickPrev()
                }}
                className={'arrow-right'}
              ><IconArrowSliderLeft size={16}/></div>
              <div onClick={() => {
                slider?.current?.slickNext()
              }}
                   className={'arrow-left'}
              >
                <IconArrowSliderRight size={16}/>
              </div>

            </Stack>
          </Stack> : null}
        </div>
        <div className={'our-team-slider'}>
          <Slider {...settings} ref={slider}>
            {data?.gallery.items.map((item, index) => {
              return <div className={'team-member-content'} key={index}>
                <Stack className={'team-member-item'}>
                  <img src={item.file} alt="team-member"/>
                  <h3>{item.title}</h3>
                  <p>{item.desc}</p>
                </Stack>
              </div>
            })}
          </Slider>
        </div>
        {window.innerWidth < 991 ? <Stack className={'slider-options'}>
          <Stack className={'slider-counter'} direction={"row"} align={"end"}>
            <h3>0{activeSlider}</h3>
            <p>/ 0{data?.gallery.items.length}</p>
          </Stack>
          <Stack direction={"row"} gap={15}>
            <div
              onClick={() => {
                slider?.current?.slickPrev()
              }}
              className={'arrow-right'}
            ><IconArrowSliderLeft size={16}/></div>
            <div onClick={() => {
              slider?.current?.slickNext()
            }}
                 className={'arrow-left'}
            >
              <IconArrowSliderRight size={16}/>
            </div>
          </Stack>
        </Stack> : null}
      </Stack>
    </Stack>
  </section>
}





