import './style.scss'
import {IconLogo} from "../../assets/icons/IconLogo";
import {Stack} from "../Stack/Stack";
import Slider from "react-slick";
import {useEffect, useRef} from "react";
import useOnScreen from "../../hook/useOnScreen";
import {IAboutContent} from "../../models/personal-settings.model";


interface Props {
  data: IAboutContent | null
}

export const WorkshopBrothers = ({data}: Props) => {
  const sliderRef = useRef<Slider>(null);
  const sectionRef = useRef<HTMLDivElement>(null)
  const sliderShowRef = useRef<HTMLDivElement>(null)
  const isVisibleSection = useOnScreen(sectionRef)
  const isVisibleSlider = useOnScreen(sliderShowRef)

  useEffect(() => {
    if (sliderRef.current && !isVisibleSlider) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickPause()
          sliderRef.current.slickGoTo(0)
        }
      }, 100)
    }
    if (isVisibleSlider && sliderRef.current) {
      setTimeout(() => {
        if (sliderRef.current) {
          sliderRef.current.slickPlay()
        }
      }, 100)
    }
  }, [isVisibleSlider])

  let settings = {
    infinite: true,
    autoplay: false,
    speed: 800,
    dots: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    fade: true
  };


  return <section className={`workshop-section G-flex ${isVisibleSection ? 'show-section' : ''}`} id={'about-us'}
                  ref={sectionRef}>
    <Stack className={'G-container'}>
      <div className='workshop-section-wrapper G-flex G-justify-end G-align-end'>
        <div className='workshop-section-users' ref={sliderShowRef}>
          <Slider {...settings}
                  ref={sliderRef}>
            {data?.gallery.items.map((item, index) => (
              <div className='workshop-slideritem' key={index}>
                <div className={'G-flex G-center workshop-images'}>
                  <img src={item.file} alt="brothers"/>
                </div>
              </div>
            ))}
          </Slider>
        </div>
        <div className={'workshop-section-info'}>
          <div className='workers-image'>
            <IconLogo size={95}/>
          </div>
          <h3>{data?.title?.value ?? ''}</h3>
          <p dangerouslySetInnerHTML={{__html: data?.desc?.value ?? ''}}></p>
        </div>
      </div>
    </Stack>
  </section>
}
