import "./style.scss";
import {MainTitle} from "../MainTitle";

import {Stack} from "../Stack/Stack";
import React, {useEffect, useRef, useState} from "react";
import Slider from "react-slick";
import useOnScreen from "../../hook/useOnScreen";
import {IAreasOfAppContent, ITableData, IUseAreaItem} from "../../models/personal-settings.model";

interface Props {
  data: IAreasOfAppContent | null
}

export const UseArea = ({data}: Props) => {

  const useAreaRef = useRef<HTMLDivElement>(null)
  const isVisibleArea = useOnScreen(useAreaRef)

  let sliderRef1 = useRef<any>(null);
  let sliderRef2 = useRef<any>(null);

  const [slider1, setSlider_1] = useState(sliderRef1.current)
  const [slider2, setSlider_2] = useState(sliderRef2.current)
  const [areaData, setAreaData] = useState<IUseAreaItem[]>([])

  const [selectImage, setSelectImage] = useState<IUseAreaItem | null>(null)

  const slider_1_settings = {
    slidesToShow: 6,
    arrows: false,
    vertical: true,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          vertical: false,
          variableWidth: true,
          infinite: true,
          rows: 1,
          focusOnSelect: true,
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          vertical: false,
          infinite: true,
          rows: 1,
          variableWidth: true,
          focusOnSelect: true,
        }
      },
    ]
  }


  useEffect(() => {
    const devDataJson: ITableData<IUseAreaItem> = JSON.parse(data?.areas_of_appElement?.value ?? '{}')
    setAreaData(devDataJson.table?.body ?? [])
    setSelectImage(devDataJson.table?.body[0])
  }, [data])

  return (
    <section>
      <Stack className='G-container'>
        <Stack className={`usearea-section ${isVisibleArea ? 'show-area' : ''}`} width={"fill"} ref={useAreaRef}>
          <MainTitle tittle={data?.title.value ?? ''} className={'usearea-title'}/>
          <div className={'slider-desktop-wrapper G-flex'}>
            <div className={'slider-navigation'}>
              {areaData.map((item, index) => {
                return <div key={index}
                            className={`slider-header-item ${selectImage?.img.fileUrl === item.img.fileUrl ? 'active' : ''}`}
                            onClick={() => {
                              setSelectImage(item)
                            }}>
                  <div
                    className={`G-align-center slider-item-mobile`}

                  >
                    <div className={'usearea-icon-content'}>
                                         <span className={'usearea-icon G-center'}>
                                        <img src={item.icon.fileUrl} alt="icon"/>
                                    </span>
                      <span className={'circle-select'}/>
                    </div>
                    <p>{item.title}</p>
                  </div>
                </div>
              })}
            </div>
            <div className={'slider-selected-image'}>
              <div className={'slider-image-content G-align-end'}
                   style={{backgroundImage: `url(${selectImage?.img?.fileUrl})`}}>
                <p>{selectImage?.desc}</p>
              </div>
            </div>
          </div>
          <div className={'slider-wrapper G-flex slider-mobile-wrapper'}>
            <div className={'slider-header'}>
              <Slider  {...slider_1_settings} asNavFor={slider2}
                       ref={sliderRef1 => setSlider_1(sliderRef1)}
                       focusOnSelect={true}
                       arrows={false}
              >
                {areaData.map((item, index) => {
                  return <div key={index} className={'slider-header-item'}>
                    <div
                      className={`G-align-center slider-item-mobile`}

                    >
                      <div className={'usearea-icon-content'}>
                                         <span className={'usearea-icon G-center'}>
                                         <img src={item.icon.fileUrl} alt="icon"/>
                                    </span>
                        <span className={'circle-select'}/>

                      </div>
                      <p>{item.title}</p>
                    </div>
                  </div>
                })}

              </Slider>
            </div>
            <div className={'main-slider'}>
              <Slider asNavFor={slider1}
                      ref={sliderRef2 => setSlider_2(sliderRef2)}
                      focusOnSelect={true}
                      fade
                      arrows={false}
              >
                {areaData.map((item, index) => {
                  return <div key={index}>
                    <div className={'usearea-selected G-align-end'}
                         style={{backgroundImage: `url(${item.img.fileUrl})`}}>
                      <p>{item.desc}</p>
                    </div>
                  </div>
                })}
              </Slider>
            </div>
          </div>
        </Stack>
      </Stack>
    </section>
  );
};
