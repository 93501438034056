import './style.scss'
import {IDevItem} from "../../models/personal-settings.model";
import {Stack} from "../Stack/Stack";
import {IconArrowRight} from "../../assets/icons/IconArrowRight";
import {IconMoreInfo} from "../../assets/icons/IconMoreInfo";
import React, {useRef} from "react";
import useOnScreen from "../../hook/useOnScreen";

interface Props {
  item: IDevItem,

  onClick(e: React.MouseEvent<HTMLElement>): void,

  className: string
}

export const EngineCard = ({item, onClick, className}: Props) => {
  const engineCardRef = useRef<HTMLHeadingElement>(null)
  const isVisibleCard = useOnScreen(engineCardRef)

  return (
    <div className={`engine-card G-flex-column G-justify-between ${isVisibleCard ? 'show-card' : ''}`} onClick={onClick}
         ref={engineCardRef}>
      <Stack gap={30} className={`engine-info ${className}`}>
        <h3>{item.title}</h3>
        <p>{item.announc}</p>
        <div style={{backgroundImage: `url(${item.thumbnail.fileUrl})`}} className={'engine-image'}/>
        <Stack className={'mobile-show-more'} onClick={onClick}>
          <IconMoreInfo size={45}/>
        </Stack>
      </Stack>
      <Stack onClick={onClick} direction={"row"} gap={10} className={'more-info'} align={"center"} width={"fitContent"}>
        <p>Подробнее</p>
        <IconArrowRight size={16}/>
      </Stack>
    </div>
  )
}
