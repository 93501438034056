import React, {useEffect, useState} from "react";
import "./assets/styles/index.scss";

import {Header} from "./components/Header/Header";
import {Cover} from "./components/Cover/Cover";
import {AboutUs} from "./components/AboutUs";
import {UseArea} from "./components/UseArea";
import {PersonalSettings} from "./components/PersonalSettings/PersonalSettings";
import {WorkshopBrothers} from "./components/WorkshopBrothers/WorkshopBrothers";
import {OurPartners} from "./components/OurPartners/OurPartners";
import {OurTeam} from "./components/OurTeam/OurTeam";
import {Footer} from "./components/Footer/Footer";
import {Auth} from "./services/api-request/Auth";
import {ICoverData, IPageData, IResponse} from "./models/personal-settings.model";
import {PageLoader} from "./components/PageLoader/PageLoader";

function App() {
  const [pageLoader, setPageLoader] = useState(false)
  const [headerData, setHeaderData] = useState<ICoverData | null>(null)
  const [pageData, setPageData] = useState<IPageData | null>(null)

  useEffect(() => {
    getCoverInfo()
  }, [])

  const getCoverInfo = async () => {
    setPageLoader(true)
    const result: [IResponse<ICoverData>, IResponse<IPageData>] = await Promise.all([Auth.getPersonalSettings(), Auth.getAllData()])
    if (result) {
      setHeaderData(result[0].data)
      setPageData(result[1].data)
    }
    setPageLoader(false)
  }
  if (pageLoader) {
    return <PageLoader/>
  }

  return (
    <>
      <Header headerData={headerData} footerData={pageData?.blocks.footer.contents ?? null}/>
      <Cover data={pageData?.blocks.index_text.contents ?? null}/>
      <PersonalSettings data={pageData?.blocks.devs.contents ?? null}/>
      <UseArea data={pageData?.blocks.areas_of_app.contents ?? null}/>
      <AboutUs data={pageData?.blocks.unique.contents ?? null}/>
      <WorkshopBrothers data={pageData?.blocks.about.contents ?? null}/>
      <OurPartners data={pageData?.blocks.our_partners.contents ?? null}/>
      <OurTeam data={pageData?.blocks.our_team.contents ?? null}/>
      <Footer data={pageData?.blocks.footer.contents ?? null}/>
    </>
  );
}

export default App;
