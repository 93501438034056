import './PageLoader.scss'
import Logo from '../../assets/images/logo.svg'

export const PageLoader = () => {
  return <div className='G-page-loader'>
    <span className='loader'>
      <img src={Logo} alt=""/>
    </span>
  </div>
}
