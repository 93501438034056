import axios, {AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig} from "axios";

const $_APIKEY = process.env.REACT_APP_API_KEY;
const onRequest = (config: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  console.log($_APIKEY)
  config.headers.set("APPTOKEN", $_APIKEY)
  return config;
}

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  console.error(`[request error] [${JSON.stringify(error)}]`);
  return Promise.reject(error);
}

const onResponse = (response: AxiosResponse): AxiosResponse => {
  // console.info(`[response] [${JSON.stringify(response)}]`);
  // if (response.status === 401 || response.status === 422) {
  //     localStorage.removeItem('accounting_access_token')
  //     window.location.reload()
  // }
  return response;
}

const onResponseError = (error: AxiosError): Promise<AxiosError> => {
  // console.error(`[response error] [${JSON.stringify(error)}]`);
  // @ts-ignore
  // if (error.response.status === 401 || error.response.status === 422) {
  //     localStorage.removeItem('accounting_access_token')
  //     window.location.reload()
  // }
  return Promise.reject(error);
}

function setupInterceptorsTo(axiosInstance: AxiosInstance): AxiosInstance {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);
  return axiosInstance;
}


class RequestService {
  private BASE_URL: string = ''

  constructor(url: string) {
    this.BASE_URL = url
    setupInterceptorsTo(axios)
  }

  GET<T, R>(path: string, configs: AxiosRequestConfig = {}) {
    return axios.get<T, AxiosResponse<R>>(this.generatePath(path), configs).catch(err => err)
  }

  POST<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
    return axios.post<T, AxiosResponse<R>>(this.generatePath(path), data, configs)
  }

  PUT<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
    return axios.put<T, AxiosResponse<R>>(this.generatePath(path), data, configs)
  }

  PATCH<T, R>(path: string, data: T, configs: AxiosRequestConfig = {}) {
    return axios.patch<T, AxiosResponse<R>>(this.generatePath(path), data, configs)
  }

  DELETE<T, R>(path: string, configs: AxiosRequestConfig = {}) {
    return axios.delete<T, AxiosResponse<R>>(this.generatePath(path), configs)
  }

  generatePath(path: string) {
    return this.BASE_URL + path;
  }
}

// export default new RequestService('http://192.168.0.10:5000/')
export default new RequestService(process.env.REACT_APP_API_SERVER || 'https://gris.planetarf.ru/api/v2')

// for Emil testing
