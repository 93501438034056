import './style.scss'

import {Stack} from "../Stack/Stack";
import Logo from '../../assets/images/logo.svg'
import React, {useEffect, useState} from "react";
import bgImage from '../../assets/images/cover-bg.png'
import {ICoverData, IFooterContent} from "../../models/personal-settings.model";
import {Skeleton} from "../Skeleton/Skeleton";


interface Props {
  headerData: ICoverData | null
  footerData: IFooterContent | null
}

export const Header = ({headerData, footerData}: Props) => {

  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const [isFixed, setIsFixed] = useState(false)
  const [contacts, setContacts] = React.useState<string[]>([])

  useEffect(() => {
    window.addEventListener('scroll', function () {
      if (window.innerWidth > 767) {
        setIsFixed(window.scrollY >= 101)
      } else {
        setIsFixed(window.scrollY >= 80)
      }
    })
  }, [])

  const handleClick = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
      let headerOffset = 100;
      let elementPosition = element.getBoundingClientRect().top;
      let offsetPosition = elementPosition + window.pageYOffset - headerOffset;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
  }

  const handleClickMenu = (title: string) => {
    handleClick(title)
    console.log()
    if (window.innerWidth <= 991) {
      setIsOpenMenu(false)
      document.body.classList.toggle('G-hidden')
    }
  }

  function getMobileOperatingSystem() {
    let isAndroid = false;
    if (/android/i.test(navigator.userAgent)) {
      isAndroid = true;
    }

    // iOS detection from: http://stackoverflow.com/a/9039885/177710
    if (/iPad|iPhone|iPod/.test(navigator.userAgent)) {
      isAndroid = false;
    }

    if (
      (navigator.userAgent.indexOf("Opera") ||
        navigator.userAgent.indexOf("OPR")) !== -1
    ) {
      return "default";
    } else if (navigator.userAgent.indexOf("Edg") !== -1) {
      return "default";
    } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
      return isAndroid ? "Chrome" : "ChromeIos";
    } else if (navigator.userAgent.indexOf("Safari") !== -1) {
      return "Safari";
    } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
      return "default";
    } else if (navigator.userAgent.indexOf("MSIE") !== -1) {
      //IF IE > 10
      return "default";
    } else {
      return "default";
    }
  }

  useEffect(() => {
    const contactsJson = JSON.parse(footerData?.contacts?.value ?? '{}')
    setContacts(contactsJson)

  }, [footerData])

  return <header className={`${isFixed ? 'fixed-header' : ''}`}>
    <Stack className={'G-container'}>
      <Stack width={'fill'} className={'header-block'} direction={"row"} align={"center"}
             justify={"space-between"}>
        <Stack className={'logo-content G-pointer'} direction={"row"} gap={15} align={"center"} onClick={() => {
          window.scrollTo({
            top: 0,
            behavior: "smooth"
          });
        }}>
          {headerData?.logo ? <img src={`${headerData?.logo}` ?? Logo} alt="logo"/> :
            <Skeleton width={'42px'} height={'46px'}/>}
          <p>Мастерская братьев Вороновых</p>
        </Stack>
        <Stack className={`menu-content ${isOpenMenu ? 'show-menu' : ''} ${getMobileOperatingSystem()}`}>
          <div className={'mobile-menu-bg'} style={{backgroundImage: `url('${bgImage}')`}}/>
          <div className={'header-mobile-scroll'}>
            <ul className={'menu-list G-flex'}>
              <li onClick={() => {
                handleClickMenu('projects')
              }}>Проекты
              </li>
              <li onClick={() => {
                handleClickMenu('advantages')
              }}>Преимущества
              </li>
              <li onClick={() => {
                handleClickMenu('about-us')
              }}>О компании
              </li>
              <li onClick={() => {
                handleClickMenu('our-team')
              }}>Команда
              </li>
              <li onClick={() => {
                handleClickMenu('contacts')
              }}>Контакты
              </li>
            </ul>
            <div className={'header-links G-flex-column'}>
              <div className={'header-links-content'}>
                {contacts.length ? contacts.map((item, index) => {
                  return <a key={index} href={`${item.indexOf('@') >= 0 ? 'mailto' : 'tel'}:${item}`}>{item}</a>
                }) : null}
              </div>
            </div>
          </div>
        </Stack>
        <div className={`toggle-menu ${isOpenMenu ? 'open-burger' : ''}`} onClick={() => {
          setIsOpenMenu(!isOpenMenu)
          document.body.classList.toggle('G-hidden')
        }}>
          <span/>
          <span/>
        </div>

      </Stack>
    </Stack>
  </header>
}


