import './style.scss'

import {Stack} from "../Stack/Stack";
import {useEffect, useRef} from "react";
import useOnScreen from "../../hook/useOnScreen";

import {Splide, SplideSlide} from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import {AutoScroll} from "@splidejs/splide-extension-auto-scroll";
import {IOurPartnersContent} from "../../models/personal-settings.model";

interface Props {
  data: IOurPartnersContent | null
}

export const OurPartners = ({data}: Props) => {

  const sectionRef = useRef<HTMLDivElement>(null);
  const refSlider = useRef<Splide>(null);
  const sectionInView = useOnScreen(sectionRef)

  useEffect(() => {
    if (refSlider.current && refSlider.current.splide && sectionInView) {
      refSlider.current.splide.go(0)
    }
  }, [sectionInView])

  return <section className={`our-partners ${sectionInView ? 'show-section' : ''}`} ref={sectionRef}>
    <Stack className={'G-container'}>
      <div className='our-partner-tittle'>
        <h3>{data?.title?.value ?? ''}</h3>
      </div>
      <div className={'partners-slider'}>
        {data?.list.items ? <Splide ref={refSlider} extensions={{AutoScroll}} options={{
          type: 'loop',
          drag: 'free',
          arrows: false,
          autoWidth: false,
          pagination: false,
          gap: 100,
          autoScroll: {
            speed: 0.8,
          },
          breakpoints: {
            1200: {
              gap: 60,
            },
            767: {
              perPage: 3,
              autoWidth: false,
            }
          },
        }}>
          {data?.list.items.map((item, index) => (
            <SplideSlide key={index}>
              <div className="partner-slider-item">
                <img src={item.file} alt={`partner-${index + 1}`}/>
              </div>
            </SplideSlide>
          ))}
        </Splide> : null}
      </div>
    </Stack>
  </section>
}
