import {ICoverData, IPageData, IResponse} from "../../models/personal-settings.model";
import RequestServices from "../request.services";


export class Auth {
  static getPersonalSettings = async () => {
    return await RequestServices.GET<null, IResponse<ICoverData>>('/app').catch(err => err)
  }

  static getAllData = async () => {
    return await RequestServices.GET<null, IResponse<IPageData>>('/appPage/home').catch(err => err)
  }
}
