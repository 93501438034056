import './skeleton.scss'
import classNames from "classnames";
import {CSSProperties} from "react";

interface Props {
    circle?: boolean
    className?: string
    animationDelay?: number
    width?: string | number,
    height?: string | number,
    radius?: number
}

export function Skeleton({circle, className, animationDelay, width='100%', height, radius}: Props) {
    const newStyles: CSSProperties = {
        maxWidth: typeof width === 'number' ? `${width}px` : width,
        height: typeof height === 'number' ? `${height}px` : height,
        animationDelay: `${animationDelay}ms`,
        ...(radius && {borderRadius: `${radius}px`})
    }

    return (
        <div style={newStyles} className={classNames('G-skeleton', circle ? 'circle' : '', className,)}>
        </div>
    );
}
